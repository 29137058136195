import { Vue } from 'vue-property-decorator';
import router from '@/router';
import axios, { AxiosInstance } from 'axios';
import {ROUTE_LOGIN} from "@/router/routes";
import ApiError from "@/api/misc/ApiError";
import store, {StoreActions} from "@/stores";
import {AuthStoreGetters} from "@/stores/auth.store";

const baseURL: string = process.env.VUE_APP_BASE_URL!;
const axiosClient: AxiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
});

/**
//  * Sets the request interceptors.
//  * Sets authorization header for each request.
//  */
axiosClient.interceptors.request.use(
    (config) => {
        // OPTIMIZE this is not optimal, the token could be expired ...
        if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
            const auth = `Bearer ${store.getters[`auth/${AuthStoreGetters.TOKEN}`]}`;
            config.headers = {...config.headers, 'authorization': auth};
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Sets the response interceptors. Normalizes the response and handles certain error cases.
 */
axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401: // Unauthorized
                    // Reset all stores:
                    store.dispatch(StoreActions.CLEAR_ALL_STORES);
                    if(router.currentRoute.name !== ROUTE_LOGIN) router.push({ name: ROUTE_LOGIN });
                    break;
                case 403:
                    // store.dispatch(StoreActions.CLEAR_ALL_STORES);
                    // if(router.currentRoute.name !== ROUTE_LOGIN) router.push({ name: ROUTE_LOGIN });
                    Vue.notifyErrorSimplified('GENERAL.ERRORS.MISSING_PERMISSION');
                    break;
                case 500: // any server error
                    Vue.notifyErrorSimplified('GENERAL.ERRORS.GENERAL_ERROR');
                    break;
                default:
                    throw new ApiError(error.response.status, error.message, error.response.data.data);
            }
        } else {
            Vue.notifyErrorSimplified('GENERAL.ERRORS.NETWORK_ERROR');
        }
        return Promise.reject(error);
    }
);

export const ENTITY_CLIENT = axiosClient;
