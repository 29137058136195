import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AxiosResponse} from "axios";

export default class AdminAccountsRepository {
    public static getUser() : Promise<AxiosResponse> {
        return ENTITY_CLIENT.get('/api/account/admin/profiles');
    }

    public static getPermissions() : Promise<AxiosResponse> {
        return ENTITY_CLIENT.get('/api/account/admin/permissions');
    }

    public static updatePermissions(id: string, scopes: string[]): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`/api/account/admin/${id}/permissions`, scopes);
    }

    public static deleteUser(accountId: string) : Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`/api/account/admin/delete/${accountId}`);
    }

    public static getUserProfileById(id: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`/api/account/admin/profiles/${id}`);
    }

    public static getUserByIdentifier(id: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`/api/account/identifier/${id}`);
    }

    /**
     * creates a new admin with the passed payload
     * @param payload
     */
    public static createAdmin(payload: {firstName: string, lastName: string, email: string, password: string,
        scopes: string[]}) : Promise<AxiosResponse> {
        return ENTITY_CLIENT.post('/api/account/admin', {
            account: {
                email: payload.email,
                password: payload.password,
                name: {
                    firstName: payload.firstName,
                    lastName: payload.lastName
                },
            },
            scopes: payload.scopes
        });
    }
}
