import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import store, {StoreActions} from '../stores';
import {
    ROUTE_LOGIN,
    ROUTE_HOME,
    ROUTE_DISPLACEMENT,
    ROUTE_GAS,
    ROUTE_NEWS,
    ROUTE_NOTIFICATION,
    ROUTE_HOME_CARDS,
    ROUTE_PARKING_PLACE,
    ROUTE_EVENTS,
    ROUTE_SPORT,
    ROUTE_SPORT_OVERVIEW,
    ROUTE_SPORT_DETAIL,
    ROUTE_REGISTER,
    ROUTE_ADMIN_AREA,
    ROUTE_DELETE_ACCOUNT,
    ROUTE_RESET_PASSWORD,
    ROUTE_GIRO_PAY,
    ROUTE_KLARNA,
    ROUTE_MAINTENANCE,
    ROUTE_STADIUM_EDITOR,
    ROUTE_STADIUM_VIEWER,
    ROUTE_ADVERTISEMENTS,
} from '@/router/routes';
import {AuthStoreGetters} from "@/stores/auth.store";
import {routeMapping} from "@/misc/NavigationServices";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: ROUTE_LOGIN,
        component: () => import(/* webpackChunkName: "admin" */ '../views/Account/Login.view.vue')
    },
    {
        path: '/giro-pay',
        name: ROUTE_GIRO_PAY,
        component: () => import(/* webpackChunkName: "giro-pay" */ '../views/GiropayPayment.view.vue')
    },
    {
        path: '/klarna',
        name: ROUTE_KLARNA,
        component: () => import(/* webpackChunkName: "klarna" */ '@/views/KlarnaPayment.view.vue')
    },
    {
        path: '/confirm-mail',
        name: ROUTE_DELETE_ACCOUNT,
        component: () => import(/* webpackChunkName: "confirm-mail" */ '../views/ConfirmMail.view.vue')
    },
    {
        path: '/confirm-deletion',
        name: ROUTE_DELETE_ACCOUNT,
        component: () => import(/* webpackChunkName: "delete-account" */ '../views/Account/DeleteAccount.view.vue')
    },
    {
        path: '/password',
        name: ROUTE_RESET_PASSWORD,
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/Password.view.vue')
    },
    {
        path: '/stadium/:id',
        name: ROUTE_STADIUM_VIEWER,
        component: () => import(/* webpackChunkName: "stadium-viewer" */ '@/views/sports/StadiumViewer.view.vue')
    },
    {
        path: '/home',
        name: ROUTE_HOME,
        beforeEnter: async(to: any, from: any, next: any) => {
            const service = routeMapping.find((s) => s.route === to.name);

            const isAllowed = service?.scope ? Vue.prototype.$hasScope(service!.scope) : true;
            if (!isAllowed) {
                await next({name: ROUTE_HOME_CARDS});
                return;
            }

            // Check if token is set. If not, redirect to login
            if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
                await next();
            } else {
                await store.dispatch(StoreActions.CLEAR_ALL_STORES);
                await next({name: ROUTE_LOGIN});
            }
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.view.vue'),
        children: [
            {
                path: 'welcome',
                name: ROUTE_HOME_CARDS,
                component: () => import(/* webChunkName: "homeCards": */ '@/views/HomeCards.view.vue')
            },
            {
                path: 'register',
                name: ROUTE_REGISTER,
                component: () => import(/* webpackChunkName: "register" */ '@/views/AdminRegister.view.vue')
            },
            {
                path: 'admin-area',
                name: ROUTE_ADMIN_AREA,
                component: () => import(/* webpackChunkName: "admin-area" */ '@/views/AdminArea.view.vue')
            },
            {
                path: 'displacement',
                name: ROUTE_DISPLACEMENT,
                component: () => import(/* webpackChunkName: "displacement" */ '@/views/Disposal.view.vue')
            },
            {
                path: 'gas',
                name: ROUTE_GAS,
                component: () => import(/* webpackChunkName: "gas" */ '@/views/GasStation.view.vue')
            },
            {
                path: 'news',
                name: ROUTE_NEWS,
                component: () => import(/* webpackChunkName: "news" */ '@/views/NewsPaper.view.vue')
            },
            {
                path: 'parking-place',
                name: ROUTE_PARKING_PLACE,
                component: () => import(/* webpackChunkName: "parking-place" */ '@/views/ParkingPlace.view.vue')
            },
            {
                path: 'maintenance',
                name: ROUTE_MAINTENANCE,
                component: () => import(/* webpackChunkName: "maintenance" */ '@/views/MaintenanceMode.view.vue')
            },
            {
                path: 'events',
                name: ROUTE_EVENTS,
                component: () => import(/* webpackChunkName: "events" */ '@/views/Events.view.vue')
            },
            {
                path: 'notification',
                name: ROUTE_NOTIFICATION,
                component: () => import(/* webpackChunkName: "news" */ '@/views/Notification.view.vue')
            },
            {
                path: ':tenantId/stadium-editor/:id',
                name: ROUTE_STADIUM_EDITOR,
                component: () => import(/* webpackChunkName: "events" */ '@/views/sports/StadiumEditor.view.vue')
            },
            {
                path: 'sport',
                name: ROUTE_SPORT,
                component: () => import(/* webpackChunkName: "sport" */ '@/views/sports/Sport.view.vue'),
                redirect: {name: ROUTE_SPORT_OVERVIEW},
                children: [
                    {
                        path: 'overview',
                        name: ROUTE_SPORT_OVERVIEW,
                        component: () => import(/* webpackChunkName: "sport-overview" */ '@/views/sports/SportsOverview.view.vue'),
                    },
                    {
                        path: 'detail/:tenant?',
                        name: ROUTE_SPORT_DETAIL,
                        component: () => import(/* webpackChunkName: "sport-detail" */ '@/views/sports/SportDetails.view.vue'),
                    },
                ]
            },
            {
                path: '',
                redirect: {name: ROUTE_HOME_CARDS}
            },
            {
                path: '*',
                redirect: {name: ROUTE_HOME_CARDS}
            },
        ]
      },
        {
            path: 'advertisements',
            name: ROUTE_ADVERTISEMENTS,
            component: () => import(/* webpackChunkName: "advertisements" */ '@/views/Advertisement.view.vue')
        },
      {
        path: '',
        redirect: {name: ROUTE_HOME_CARDS}
      },
      {
        path: '*',
        redirect: {name: ROUTE_HOME_CARDS}
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
