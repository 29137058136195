import {
    ROUTE_ADMIN_AREA,
    ROUTE_ADVERTISEMENTS,
    ROUTE_DISPLACEMENT,
    ROUTE_EVENTS,
    ROUTE_GAS, ROUTE_MAINTENANCE,
    ROUTE_NEWS,
    ROUTE_NOTIFICATION,
    ROUTE_PARKING_PLACE,
    ROUTE_REGISTER,
    ROUTE_ROUTES,
    ROUTE_SPORT
} from "@/router/routes";
import {SupportedScope} from "@/enum/SupportedScopes";
import i18n from "@/i18n";

export interface AdminNavigationService {
    text: string,
    action?: () => void,
    icon: string,
    route: string,
    scope?: SupportedScope
}

export interface NavigationService {
    text: string,
    route: string,
    icon: string,
    scope: SupportedScope[],
    routeTo?: string
}

export interface SportServiceNavigation {
    route?:string,
    text:string,
    image:string,
    scope?:SupportedScope
}

export interface RouteMapping {
    route: string,
    scope?: SupportedScope[]
}

export const routeMapping: RouteMapping[] = [
    {
        route: ROUTE_REGISTER,
        scope: [SupportedScope.ALL]
    },
    {
        route: ROUTE_ADMIN_AREA,
        scope: [SupportedScope.ALL]
    },
    {
        route: ROUTE_GAS,
        scope: [SupportedScope.GAS_STATIONS]
    },
    {
        route: ROUTE_DISPLACEMENT,
        scope: [SupportedScope.DISPOSAL]
    },
    {
        route: ROUTE_NEWS,
        scope: [SupportedScope.NEWS]
    },
    {
        route: ROUTE_PARKING_PLACE,
        scope: [SupportedScope.PARKING]
    },
    {
        route: ROUTE_EVENTS,
        scope: [SupportedScope.EVENTS]
    },
    {
        route: ROUTE_NOTIFICATION,
        scope: [SupportedScope.NOTIFICATIONS]
    },
    {
        route: ROUTE_SPORT,
        scope: [SupportedScope.TICKETING]
    },
    {
        route: ROUTE_MAINTENANCE,
        scope: [SupportedScope.ALL]
    },
    {
        route: ROUTE_ADVERTISEMENTS,
        scope: [SupportedScope.ADVERTISEMENT]
    },
];

export const adminNavigationServices: AdminNavigationService[] = [
    {
        text: i18n.t('NAVIGATION.ADD_USER').toString(),
        route: ROUTE_REGISTER,
        icon: 'mdi-account-plus',
        scope: SupportedScope.ALL
    },
    {
        text: i18n.t('NAVIGATION.ADMIN_AREA').toString(),
        route: ROUTE_ADMIN_AREA,
        icon: 'mdi-cog',
        scope: SupportedScope.ALL
    }
];

export const sportNavigationServices: SportServiceNavigation[] = [
    {
        text: i18n.t('SPORT.CLUBS.HC').toString(),
        image: require("@/assets/images/Eintracht_Hildesheim.png")
    },
    {
        text: i18n.t('SPORT.CLUBS.HELIOS').toString(),
        image: require("@/assets/images/helios_grizzlys.png")
    },
    {
        text: i18n.t('SPORT.CLUBS.INVADERS').toString(),
        image: require("@/assets/images/Invaders-Sponsoren.png")
    },
    {
        text: i18n.t('SPORT.CLUBS.VFV').toString(),
        image: require("@/assets/images/VfV_06_Hildesheim_Logo.png")
    },
];



export const navigationServices: NavigationService[] = [
    {
        text: i18n.t('NAVIGATION.SERVICES.DISPLACEMENTS').toString(),
        route: ROUTE_DISPLACEMENT,
        icon: 'mdi-delete',
        scope: [SupportedScope.DISPOSAL]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.GAS_STATION').toString(),
        route: ROUTE_GAS,
        icon: 'mdi-gas-station',
        scope: [SupportedScope.GAS_STATIONS]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.NEWS').toString(),
        route: ROUTE_NEWS,
        icon: 'mdi-newspaper',
        scope: [SupportedScope.NEWS]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.NOTIFICATION').toString(),
        route: ROUTE_NOTIFICATION,
        icon: 'mdi-bell',
        scope: [SupportedScope.NOTIFICATIONS]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.PARKING_PLACE').toString(),
        route: ROUTE_PARKING_PLACE,
        icon: 'mdi-car-brake-parking',
        scope: [SupportedScope.PARKING]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.EVENTS').toString(),
        route: ROUTE_EVENTS,
        icon: 'mdi-calendar',
        scope: [SupportedScope.EVENTS]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.SPORT').toString(),
        route: ROUTE_SPORT,
        icon: 'mdi-basketball',
        scope: [SupportedScope.TICKETING, SupportedScope.TICKETING_EH,
            SupportedScope.TICKETING_VFV, SupportedScope.TICKETING_HI,
            SupportedScope.TICKETING_HG, SupportedScope.TICKETING_JO
        ],
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.ROUTES').toString(),
        route: ROUTE_ROUTES,
        icon: 'mdi-routes',
        scope: [SupportedScope.ROUTES],
        routeTo: process.env.VUE_APP_ROUTES_FRONTEND
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.MAINTENANCE').toString(),
        route: ROUTE_MAINTENANCE,
        icon: 'mdi-wrench-clock',
        scope: [SupportedScope.ALL]
    },
    {
        text: i18n.t('NAVIGATION.SERVICES.ADVERTISEMENT').toString(),
        route: ROUTE_ADVERTISEMENTS,
        icon: 'mdi-bullhorn',
        scope: [SupportedScope.ADVERTISEMENT]
    },
];
