/**
 * Simple error wrapper class to normalize error cases
 */
export default class ApiError {

    /**
     * http status code
     */
    public status: number;

    /**
     * error message
     */
    public message: string;

    /**
     * error data
     */
    public data: any;

    public path: string | undefined;

    public timestamp: string | undefined;

    constructor(status: number, message: string, data: any, path?: string, timestamp?: string) {
        this.status = status;
        this.message = message;
        this.data = data;
        this.path = path;
        this.timestamp = timestamp;
    }
}
